'use strict';

//DEV 
//export var apiurl = 'https://rxglobalwappdevtestapioi.azurewebsites.net/';
 //export var apiurl = 'https://rxglobalwappuatapioi.azurewebsites.net/';
 //export var apiurl = 'https://rxglobalwappuatapioi.azurewebsites.net/'; 
 //PROD
 //export var apiurl = 'https://rxglobalwappdevtestapioipwa.azurewebsites.net/';
 //DevPWA
//export var apiurl = 'https://rxglobalwappdevtestapioipwa.azurewebsites.net/' ;  //DEVPWA

// export var apiurl = 'https://rxglobalwappuatapiordintell.azurewebsites.net/'; //UAT

export var apiurl = 'https://rxglobalwappprodapioi.azurewebsites.net/'; // PROD
 
// 'https://oiapp.gsk.com/';
// 'http://192.168.0.62:8443/';
//export let apiurl = 'http://localhost:8443/';
// 'http://localhost:8443/';
// 'https://oiapp.gsk.com/';

